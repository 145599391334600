import img1 from '../images/post/imgpost1.jpg'
import img2 from '../images/post/imgpost2.jpg'
import img3 from '../images/post/imgpost3.jpg'

const dataBlog = [
    {
        id: 1,
        img : img1,
        category: 'digitalart',
        title: 'Join the Invaders!',
        time: '20 Jun 2022',
        text: 'The year is 4444 and the Crypto Galaxy is in turmoil. To save all...',
        link: 'https://medium.com/@cosmicaliens/join-the-invaders-ef92ca71094d'
    },
    {
        id: 2,
        img : img2,
        category: 'nftartwork',
        title: 'The Cosmic Aliens Vision',
        time: '20 Jun 2022',
        text: 'With unmatched interoperability, low to no gas fees, programming...',
        link: 'https://medium.com/@cosmicaliens/the-cosmic-vision-419cf98eafe8'
    },
    {
        id: 3,
        img : img3,
        category: 'digitalart',
        title: 'Why Cosmos?',
        time: '20 Jun 2022',
        text: 'Cosmos, in itself, is not a blockchain but rather a framework...',
        link: 'https://medium.com/@cosmicaliens/why-cosmos-f11057d3eb33'
    },
]

export default dataBlog;
