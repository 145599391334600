
import img1 from '../images/logo/logo1.png'
import img2 from '../images/logo/logo2.png'
import img3 from '../images/logo/logo3.png'
import img4 from '../images/logo/logo4.png'
import img5 from '../images/logo/logo5.png'
import img6 from '../images/logo/logo6.png'

const dataPartner = [
    {
        img: img1,
    },
    {
        img: img2,
    },
    {
        img: img3,
    },
    {
        img: img4,
    },
    {
        img: img5,
    },
    {
        img: img6,
    },
]

export default dataPartner;
